<template>
  <CyMenu
    :value="isOpen"
    offset-y
    bottom
    :left="alignX === 'left'"
    :right="alignX === 'right'"
    :close-on-content-click="false"
    min-width="0"
    origin="top right"
    @input="(value) => { isOpen = value }">
    <template #activator="{ on }">
      <slot
        name="activator"
        :label="activatorLabel"
        :on="on">
        <CyButton
          :theme="activatorLabel ? 'primary' : 'grey'"
          :variant="activatorLabel ? 'secondary' : 'tertiary'"
          :icon="activatorLabel ? 'event' : ''"
          :class="[{ 'pr-1': activatorLabel }]"
          v-on="on">
          {{ activatorLabel || $t('forms.field.timeRange') }}
          <v-icon class="pl-2">
            arrow_drop_down
          </v-icon>
        </CyButton>
      </slot>
    </template>
    <v-card
      v-click-outside="{
        handler: _.debounce(populateValues, 200),
        closeConditional: () => selectedRange === 'custom',
      }"
      class="date-time-range">
      <div class="d-flex">
        <v-list
          class="relative-options"
          dense>
          <v-list-item-group
            v-model="selectedRange"
            mandatory
            @change="changeRange">
            <v-list-item
              v-for="{ label, value: optionValue } in relativeOptions"
              :key="label"
              :value="optionValue"
              @click="isOpen = false">
              {{ label }}
            </v-list-item>
            <v-list-item
              class="mt-4"
              value="custom"
              tabindex="1">
              {{ $t('forms.field.timeRangeCustom') }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider
          class="mr-1"
          vertical/>
        <div class="d-flex flex-column align-stretch pa-4">
          <v-date-picker
            :key="`${selectorDates[0]}-${selectorDates[1]}`"
            v-model="selectorDates"
            color="secondary"
            range
            no-title
            show-adjacent-months
            class="mb-2"
            :max="maxRangeDate"
            @input="() => { if (!isCustomRange) { selectedRange = 'custom'; changeRange('custom'); } }"
            @change="checkChronologicalOrder()"/>
          <div class="d-flex space-x-4">
            <v-text-field
              :key="`from-${selectorDates[0]}-${selectorTimes[0]}-${formattedDateUpdateWatcher}`"
              :value="formattedStartDate"
              :label="$t('forms.field.timeRangeStart')"
              :readonly="!isCustomRange"
              required
              class="required-field"
              tabindex="1"
              @keydown.tab.stop
              @keydown.enter="emitDataEvent(); isOpen = false"
              @change="value => { formattedStartDate = value; checkChronologicalOrder(true); }"/>
            <v-text-field
              :key="`to-${selectorDates[1]}-${selectorTimes[1]}-${formattedDateUpdateWatcher}`"
              :value="formattedEndDate"
              :label="$t('forms.field.timeRangeEnd')"
              :readonly="!isCustomRange"
              required
              class="required-field"
              tabindex="1"
              @keydown.tab.stop
              @keydown.enter="emitDataEvent(); isOpen = false"
              @change="value => { formattedEndDate = value; checkChronologicalOrder(true); }"/>
          </div>
          <div
            v-if="isCustomRange"
            class="actions d-flex justify-end space-x-4">
            <CyButton
              v-if="!mandatory"
              variant="secondary"
              theme="grey"
              sm
              @click="emptyDates">
              {{ $t('forms.btnClear') }}
            </CyButton>
            <CyButton
              icon="check"
              sm
              :disabled="!selectorDates[0] || !selectorDates[1]"
              @click="emitDataEvent(); isOpen = false">
              {{ $t('forms.btnApply') }}
            </CyButton>
          </div>
        </div>
      </div>
    </v-card>
  </CyMenu>
</template>

<script>
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyInputsDateTimeRange',
  props: {
    relativeOptions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Object],
      default: null,
    },
    alignX: {
      type: String,
      default: 'left',
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    activatorLabelFormat: {
      type: String,
      default: 'MMM DD, YYYY',
    },
    futureDatesDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectorDates: [null, null],
    selectorTimes: ['00:00', '23:59'],
    selectedRange: null,
    formattedDateUpdateWatcher: false,
    isOpen: false,
  }),
  computed: {
    formattedStartDate: {
      get () {
        return this.formatDate(this.selectorDates[0], this.selectorTimes[0])
      },
      set (dateString) {
        if (this.isFutureDate(dateString)) {
          this.parseFormattedDate(moment().format('MMM DD, YYYY HH:mm'), 0, '00:00') // eslint-disable-line you-dont-need-momentjs/format
          return
        }
        this.parseFormattedDate(dateString, 0, '00:00')
      },
    },
    formattedEndDate: {
      get () {
        return this.formatDate(this.selectorDates[1], this.selectorTimes[1])
      },
      set (dateString) {
        if (this.isFutureDate(dateString)) {
          this.parseFormattedDate(moment().format('MMM DD, YYYY HH:mm'), 1, '23:59') // eslint-disable-line you-dont-need-momentjs/format
          return
        }
        this.parseFormattedDate(dateString, 1, '23:59')
      },
    },
    maxRangeDate () {
      return this.futureDatesDisabled ? moment().format('YYYY-MM-DD') : undefined // eslint-disable-line you-dont-need-momentjs/format
    },
    isCustomRange () {
      return this.selectedRange === 'custom'
    },
    currentRangeLabel () {
      if (!this.selectedRange) return null
      if (this.isCustomRange) return this.$t('forms.field.timeRangeCustom')
      const relativeOption = _.find(this.relativeOptions, { value: this.selectedRange })
      return _.get(relativeOption, 'label', null)
    },
    activatorLabel () {
      if (!this.selectedRange) return this.$t('pickADate')
      if (!this.isCustomRange) return this.currentRangeLabel
      if (!this.selectorDates[0] && !this.selectorDates[1]) return this.$t('pickADate')
      const dateFormatter = (index) => {
        if (!this.selectorDates[index]) return '____________'
        return _.capitalize(moment(`${this.selectorDates[index]} ${this.selectorTimes[index]}`).format(this.activatorLabelFormat)) // eslint-disable-line you-dont-need-momentjs/format
      }
      return `${dateFormatter(0)} - ${dateFormatter(1)}`
    },
  },
  mounted () {
    this.populateValues()
    this.emitDataEvent()
  },
  methods: {
    populateValues () {
      if (!this.value) return
      if (_.isString(this.value)) {
        this.selectedRange = this.value
        const { dates, times } = this.getDatesFromRange(this.value)
        this.selectorDates = dates
        this.selectorTimes = times
        return
      }
      if (!this.value.begin || !this.value.end) return
      this.selectedRange = 'custom'
      this.selectorDates = Object.values(this.value).map((value) => moment(value).format('YYYY-MM-DD')) // eslint-disable-line you-dont-need-momentjs/format
      this.selectorTimes = Object.values(this.value).map((value) => moment(value).format('HH:mm')) // eslint-disable-line you-dont-need-momentjs/format
    },
    checkChronologicalOrder (swapDifferentDayTimes = false) {
      const startDate = $date.parse(this.formattedStartDate, 'MMM dd, yyyy HH:mm', new Date())
      const endDate = $date.parse(this.formattedEndDate, 'MMM dd, yyyy HH:mm', new Date())
      if (!$date.isAfter(startDate, endDate)) return
      if ($date.isSameDay(startDate, endDate)) this.selectorTimes = this.selectorTimes.reverse()
      else {
        this.selectorDates = this.selectorDates.reverse()
        this.selectorTimes = swapDifferentDayTimes ? this.selectorTimes.reverse() : this.selectorTimes
      }
    },
    parseFormattedDate (dateString, index, defaultTime) {
      const dateTimeValidatedObject = moment(dateString, 'MMM DD, YYYY HH:mm', true)
      if (dateTimeValidatedObject.isValid()) {
        this.$set(this.selectorDates, index, dateTimeValidatedObject.format('YYYY-MM-DD')) // eslint-disable-line you-dont-need-momentjs/format
        this.$set(this.selectorTimes, index, dateTimeValidatedObject.format('HH:mm')) // eslint-disable-line you-dont-need-momentjs/format
        return
      }

      this.$set(this.selectorTimes, index, defaultTime)

      const dateValidatedObject = moment(dateString.slice(0, 12), 'MMM DD, YYYY', true)
      if (dateValidatedObject.isValid()) {
        this.$set(this.selectorDates, index, dateValidatedObject.format('YYYY-MM-DD')) // eslint-disable-line you-dont-need-momentjs/format
        return
      }

      this.formattedDateUpdateWatcher = !this.formattedDateUpdateWatcher
    },
    getDatesFromRange (range) {
      const currentTime = $date.getTime(Date.now())
      const [count, unit] = range.match(/[a-zA-Z]+|[0-9]+/g)
      const useExactTime = ['H', 'm', 's'].includes(unit)
      const units = {
        d: 'days',
        H: 'hours',
        m: 'minutes',
        s: 'seconds',
      }
      const startDate = $date.sub(currentTime, { [units[unit]]: count }).getTime()
      const endDate = currentTime
      const dates = [startDate, endDate].map((date) => $date.format(date, 'yyyy-MM-dd'))
      const times = [useExactTime ? $date.format(startDate, 'HH:mm') : '00:00', '23:59']
      return { dates, times }
    },
    changeRange (range) {
      if (range === 'custom') {
        this.selectorTimes = ['00:00', '23:59']
        return
      }
      const { dates, times } = this.getDatesFromRange(range)
      this.selectorDates = dates
      this.selectorTimes = times
      this.emitDataEvent()
    },
    emptyDates () {
      this.selectorDates = [null, null]
      this.selectorTimes = ['00:00', '23:59']
      this.emitDataEvent()
    },
    formatDate (date, time) {
      return date
        ? moment(`${date} ${time}`).format('MMM DD, YYYY HH:mm') // eslint-disable-line you-dont-need-momentjs/format
        : ''
    },
    // eslint-disable-next-line vue/no-unused-properties
    refresh () {
      if (this.selectedRange && !this.isCustomRange) this.changeRange(this.selectedRange)
      else this.emitDataEvent()
    },
    isFutureDate (dateString) {
      return $date.isAfter($date.parse(dateString, 'MMM dd, yyyy HH:mm', new Date()), new Date()) && this.futureDatesDisabled
    },
    emitDataEvent () {
      this.$emit('change', {
        label: this.currentRangeLabel,
        range: this.isCustomRange ? null : this.selectedRange,
        value: {
          begin: this.selectorDates[0] ? moment(`${this.selectorDates[0]} ${this.selectorTimes[0]}`).valueOf() : null,
          end: this.selectorDates[1] ? moment(`${this.selectorDates[1]} ${this.selectorTimes[1]}`).valueOf() : null,
        },
      })
    },
  },
  i18n: {
    messages: {
      en: {
        pickADate: 'Pick a date',
      },
      es: {
        pickADate: 'Seleccione una fecha',
      },
      fr: {
        pickADate: 'Choisir une date',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .relative-options {
    width: 168px;
  }

  .date-time-range {
    ::v-deep .v-text-field {
      &.v-input--is-readonly .v-input__slot::before {
        border: none;
      }

      input {
        max-width: 124px;
        font-size: $font-size-default;
      }
    }

    ::v-deep .v-date-picker-table {
      height: unset;
      padding-right: 0;
      padding-left: 0;
    }

    ::v-deep .v-list-item--active {
      background: cy-get-color("secondary", "light-4");
      color: cy-get-color("secondary", "dark-1");
    }

    ::v-deep.v-picker--date {
      .v-date-picker-table--date button {
        &.v-btn--disabled.v-btn--active {
          color: white !important;
        }
      }
    }
  }
</style>
